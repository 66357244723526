import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Services.css";
import curr from '../../images/IMG_4795.png';

class CurriculumService extends Component {
    render(){
        return(
            <>
            <Header/>
            <div>
                <img src = {curr} class='center' alt = 'classroom'/>
                <div class="text-block-curriculum">
                    <h1 className="font-text">
                        Curriculum and Program Design
                    </h1>
                    <p className="font-text">
                    Curriculum incorporates real-world, hands-on, inquiry-based lessons and is written to enhance global awareness, 
                    environmental issues, and career awareness. The Educational Programs focus on Science, Technology, Engineering, 
                    and Math with an emphasis on 21st-century skills including communication, collaboration, critical thinking, and 
                    problem-solving. Curriculum is aligned with the Next Generation Science Standards (NGSS), Math and Literacy Common 
                    Core, the National Core Arts Standards, and the International Society for Technology in Education (ISTE) Standards. 
                    </p>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default CurriculumService;