import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Contact.css"

class Contact extends Component {
    render(){
        return(
            <>
            <Header/>
            <div className="contact">
                <div className="contact-block">
                    <h1 className="font-text">
                        Contact Us
                    </h1>
                    <p className="font-text">
                        If you have any questions about our services or how we can properly assist you and your group please reach out.
                    </p>
                    <p className="font-text text2">Email: improvingthefuture1@gmail.com</p>
                    <p className="font-text text2">Phone: (860) 580-9707</p>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default Contact;