import React, { Component } from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavText,
} from './HeaderUtils.js';
import "./Header.css"
import logo from '../../images/Improving_the_Future_Logo.png';

class Header extends Component {
  render() {
    return(
        <header className="header">
          <img src = {logo} width='100' alt = 'logo'/>
          <Nav>
              <NavText className='spacer'>
                Improving The Future
              </NavText>
              <Bars/>
              <NavMenu>
                  <NavLink to='/home' activeStyle className='text-link'>
                      Home
                  </NavLink>
                  <NavLink to='/services' activeStyle className='text-link'>
                      Our Services
                  </NavLink>
                  <NavLink to='/about' activeStyle className='text-link'>
                      About Us
                  </NavLink>
              </NavMenu>
              <NavBtn>
                  <NavBtnLink to='/contact' className='nav-btn'>Contact Us</NavBtnLink>
              </NavBtn>
          </Nav>
        </header>
    );
  }
}
export default Header;