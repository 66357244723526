import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Services.css";
import prof from '../../images/IMG_0111.png';

class ProfessionalService extends Component {
    render(){
        return(
            <>
            <Header/>
            <div>
                <img src = {prof} class='center' alt = 'classroom'/>
                <div class="text-block">
                    <h1 className="font-text">
                        Professional Learning and Public Speaking
                    </h1>
                    <p className="font-text">
                    Delivering research-based content with authentic presentations that engage, energize, 
                    inspire, and captivate learners.  Professional learning is the key component to improving 
                    the necessary knowledge and skills of an individual to enable success and growth. 
                     Professional learning is designed to improve an individual’s practice, academic knowledge, 
                     and self-reflection, and develop a deeper understanding of oneself and the customer or student.
                    </p>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default ProfessionalService;