import React, { Component } from 'react';
import "./Footer.css";

class Footer extends Component {
    render(){
        return(
            <footer className="footer">
                <div className='footer-box'>
                    <div className = 'footer-box-text'>The future is calling,<br/>Will you answer?</div>
                </div>
                {/*<div className='footer-links'>
                    <a href='https://www.instagram.com/' className='footer-links-text'>Follow Us</a> 
                    <a href='https://www.instagram.com/' className='footer-links-text'>Testomonials</a>
                </div>*/}
            </footer>
        );
    }
}
export default Footer;