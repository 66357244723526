import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Services.css";
import spec from "../../images/IMG_4674.png";

//todo add description
class SpecialService extends Component {
    render(){
        return(
            <>
            <Header/>
            <div>
                <img src = {spec} class='center' alt = 'classroom'/>
                <div class="text-block">
                    <h1 className="font-text">
                        Special Eduation Consulting
                    </h1>
                    <p className="font-text">
                    Special education is designed to help children who struggle with academic or social situations 
                    be able to achieve success with minimal restrictions. Services 
                    include initial eligibility testing, triennial testing, and advocating in IEP/504 meetings.
                    </p>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default SpecialService;