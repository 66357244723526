import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
NavBtn,
NavBtnLink,
} from '../../components/Header/HeaderUtils';
import "./Home.css";
import IMG_4837 from '../../images/IMG_4837.png';
import grid1 from '../../features/Icons/Grid.svg';
import grid2 from '../../features/Icons/Grid2.svg';
import grid3 from '../../features/Icons/Grid3.svg';
import feature1 from '../../features/feature1.svg';
import feature2 from '../../features/feature2.svg';
import feature3 from '../../features/feature3.svg';
import feature4 from '../../features/feature4.svg';

class Home extends Component {
    render(){
        
        return(
            <>
                <Header/>
                <div>
                    <img src = {IMG_4837} class='center' alt = 'classroom'/>
                    <div class="text-block">
                        <h1 className="font-text">
                            Making Education Accessible to All.
                        </h1>
                        <p className="font-text">
                        Improving the Future LLC is an eduational entitiy that encompasses STEM Consulting, Special Eduation Services, Curriculum and Program design, Website design, and After school services including tutoring. 
                        </p>
                    </div>
                </div>

                <h1 className="font-text-space">What We Do</h1>
                <div className="center-text">
                    <div className="frame">
                        <div className="group">
                            <div className="div">
                                <div className="first-feature-wrapper">
                                    <div className="first-feature">Curriculum and Program Design</div>
                                </div>
                                <div className="icons-grid-wrapper">
                                    <img className="icons-grid" alt="Icons grid" src={grid1} />
                                </div>
                            </div>
                            <div className="group-2">
                                <div className="second-feature-wrapper">
                                    <div className="text-wrapper">Professional Learning and Public Speaking</div>
                                </div>
                                <div className="icons-grid-wrapper">
                                    <img className="icons-grid" alt="Icons grid" src={grid2} />
                                </div>
                            </div>
                            <div className="group-3">
                                <div className="third-feature-wrapper">
                                    <div className="text-wrapper">After School Programming and Tutoring</div>
                                </div>
                                <div className="icons-grid-wrapper">
                                    <img className="icons-grid" alt="Icons grid" src={grid3} />
                                </div>
                            </div>
                        </div>
                        <div className="group">
                            <div className="div">
                                <div className="first-feature-wrapper">
                                    <div className="first-feature">Website and Application Design</div>
                                </div>
                                <div className="icons-grid-wrapper">
                                    <img className="icons-grid" alt="Icons grid" src={grid3} />
                                </div>
                            </div>
                            <div className="group-2">
                                <div className="second-feature-wrapper">
                                    <div className="text-wrapper">STEM Education Consulting</div>
                                </div>
                                <div className="icons-grid-wrapper">
                                    <img className="icons-grid" alt="Icons grid" src={grid1} />
                                </div>
                            </div>
                            <div className="group-3">
                                <div className="third-feature-wrapper">
                                    <div className="text-wrapper">Special Eduation Consulting</div>
                                </div>
                                <div className="icons-grid-wrapper">
                                    <img className="icons-grid" alt="Icons grid" src={grid2} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center-text">
                        <NavBtn>
                            <NavBtnLink to='/services'>Learn More</NavBtnLink>
                        </NavBtn>
                    </div>
                </div>

                <div className="section">
                    <div className="title-col-left">
                        <h1 className="font-text">Benefits of Working With Us</h1>
                    </div>
                    <div className="center-text">
                    <div className="row">
                        <div className="element">
                        <img className="feature-icon-with" alt="Feature icon with" src={feature1} />
                        <div className="details">
                            <p className="headline">Experienced Educators</p>
                            <p className="description">
                            Work with professional educators with over 20 years of experience in the field of education ranging from pre-kindergarten to 12+.
                            </p>
                        </div>
                        </div>
                        <div className="element">
                        <img className="feature-icon-with" alt="Feature icon with" src={feature2} />
                        <div className="details">
                            <div className="headline">Personalized Care</div>
                            <p className="description">
                            Our specialists are dedicated to providing one-on-one attention to ensure that every client obtains their desired outcomes.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="div">
                        <div className="element">
                        <img className="feature-icon-with" alt="Feature icon with" src={feature3} />
                        <div className="details">
                            <div className="headline">Hands-On, Inquiry-Based, Research Approach</div>
                            <p className="description">
                            According to the Harvard Gazette and the National Academy of Sciences, 
                            students learn more when participating in active learning scenarios. 
                            We believe that using real-world experiences and hands-on learning improves 
                            a child's cognitive ability and raises career awareness.
                            </p>
                        </div>
                        </div>
                        <div className="element">
                        <img className="feature-icon-with" alt="Feature icon with" src={feature4} />
                        <div className="details">
                            <div className="headline">Content Knowledgeable</div>
                            <p className="description">
                            Our team has spent many years in the field and is prepared to bring everything 
                            we learned to the table for your benefit. Our team has experience working with both children and adults in the 
                            following fields; education, multi-cultural learning, literacy, science, math, engineering, technology, and special education.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                
                <div className="inspire">
                    <div className="center-text">
                        <h1 style={{marginTop:0, fontFamily: 'Times New Roman, Times, serif'}}>Lets Work Together to Improve the Future One Child at a Time</h1>
                    </div>
                    <div className="btn" >
                        <NavBtn>
                            <NavBtnLink to='/contact'>Contact Us Today!</NavBtnLink>
                        </NavBtn>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}
export default Home;