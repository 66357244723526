import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Services.css";
import code from "../../images/code2.jpg";

//todo add description
class WebService extends Component {
    render(){
        return(
            <>
            <Header/>
            <div>
                <img src = {code} class='center' alt = 'classroom'/>
                <div class="text-block">
                    <h1 className="font-text">
                        Web and Application Design
                    </h1>
                    <p className="font-text">
                        Web design is the process of creating the visual and functional elements of a website to ensure it is 
                        aesthetically pleasing, user-friendly, and effectively conveys its intended message or serves its purpose. 
                        We wll work wiht you in a step by step process to design the website of your dreams.
                    </p>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default WebService;