import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Services.css"
import {
    NavLink,
  } from '../../components/Header/HeaderUtils';
import curr from '../../images/IMG_5564.png';
import pub from '../../images/IMG_0065.png';
import after from '../../images/IMG_4696.png';
import code from '../../images/code1.jpg';
import spec from '../../images/IMG_4817.png';
import stem from '../../images/stem.png';

class Services extends Component {
    render(){
        return(
            <>
            <Header/>
            <div className="center-text">
                <h1>Our Services</h1>
            </div>
            <div className="grid">
                <div className="container">
                    <img src = {curr} class='resize-cp' alt = 'classroom'/>
                    <div className="text-box bR">
                        <h2 className="font-text">
                            Curriculum and Program Design
                        </h2>
                        <NavLink to='/services/c&pd' activeStyle>Learn More <i class="arrow right"/></NavLink>
                    </div>
                </div>
                <div className="container">
                    <img src = {pub} class='resize-pl' alt = 'classroom'/>
                    <div className="text-box bL">
                        <h2 className="font-text">
                            Professional Learning and Public Speaking
                        </h2>
                        <NavLink to='/services/pl&ps' activeStyle>Learn More <i class="arrow right"/></NavLink>
                    </div>
                </div>
                <div className="container">
                    <img src = {after} class='resize-after' alt = 'classroom'/>
                    <div className="text-box mR">
                        <h2 className="font-text">
                            After School Programing and Tutoring
                        </h2>
                        <NavLink to='/services/asp&t' activeStyle>Learn More <i class="arrow right"/></NavLink>
                    </div>
                </div>
                <div className="container">
                    <img src = {code} class='resize-web' alt = 'classroom'/>
                    <div className="text-box mL">
                        <h2 className="font-text">
                            Web and Application Design
                        </h2>
                        <NavLink to='/services/w&ad' activeStyle>Learn More <i class="arrow right"/></NavLink>
                    </div>
                </div>
                <div className="container">
                    <img src = {spec} class='resize-special' alt = 'classroom'/>
                    <div className="text-box tR">
                        <h2 className="font-text">
                            Special Education Consulting
                        </h2>
                        <NavLink to='/services/sec' activeStyle>Learn More <i class="arrow right"/></NavLink>
                    </div>
                </div>
                <div className="container">
                    <img src = {stem} class='resize-consult' alt = 'classroom'/>
                    <div className="text-box tL">
                        <h2 className="font-text">
                            STEM Education Consulting
                        </h2>
                        <NavLink to='https://drstaceyawstemconsultant.org/' activeStyle>Learn More <i class="arrow right"/></NavLink>
                    </div>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default Services;