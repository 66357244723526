import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Services.css";
import after from '../../images/IMG_4677.png';

class AfterService extends Component {
    render(){
        return(
            <>
            <Header/>
            <div>
                <img src = {after} class='center' alt = 'classroom'/>
                <div class="text-block">
                    <h1 className="font-text">
                        After School Programing and Tutoring
                    </h1>
                    <p className="font-text">
                    Individualized standards-based learning developed to improve and enhance students' learning.  
                    All learning incorporates and supports multiple learning styles to ensure the development and 
                    growth of all students.
                    </p>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}
export default AfterService;