import './App.css';
import Home from './pages/Home/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Services from './pages/Services/Services';
import CurriculumService from './pages/Services/CurriculumService';
import ProfessionalService from './pages/Services/ProfessionalService';
import AfterService from './pages/Services/AfterService';
import WebService from './pages/Services/WebService';
import SpecialService from './pages/Services/SpecialService';
import AboutUs from './pages/AboutUs/AboutUs';
import Contact from './pages/Contact/Contact';

function App() {
  return (
    <Router>
      <Routes>
        {/*Home Page*/}
        <Route exact path="/" element={ <Navigate to="/home" />}/>
        <Route exact path="/home" element={<Home/>} />
        <Route exact path="/services" element={<Services/>} />
        <Route exact path="/services/c&pd" element={<CurriculumService/>}/>
        <Route exact path="/services/pl&ps" element={<ProfessionalService/>}/>
        <Route exact path="/services/asp&t" element={<AfterService/>}/>
        <Route exact path="/services/w&ad" element={<WebService/>}/>
        <Route exact path="/services/sec" element={<SpecialService/>}/>
        <Route exact path="/about" element={<AboutUs/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
      </Routes>
    </Router>
  );
}

export default App;
