import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./AboutUs.css";
import about from "../../images/IMG_2092.png";

//todo add description
class AboutUs extends Component {
    render(){
        return(
            <>
            <Header/>
            <div>
                <img src = {about} class='center' alt = 'classroom'/>
                <div class="text-block">
                    <h1 className="font-text">
                        Our Story
                    </h1>
                </div>
            </div>
            <div>
                <p className="about-text">
                    Improving the Future LLC is an educational entity that encompasses STEM Consulting, Special Education Services, 
                    Curriculum and Program Design, and After School Services including Tutoring.
                </p>
                <p className="about-text">
                    Kaziya Watson has a Bachelor of Science in childhood education and Special Education, and a Master of Science in Special 
                    Education mild/moderate and severe. She has done many different jobs including advocating for student disability, ABA assistants, 
                    and special education testing, and is currently a Life skills teacher. Ms. Watson has been working with children for 8 years 
                    and has a passion that can not be denied. She is devoted to being a great educator in any way possible and helping every child that she can. 
                </p>
                <p className="about-text">
                    Dr. Stacey Williams has a Bachelor of Science in Health Education, a Master of Arts in Curriculum and Instruction, 
                    and a Ph.D. in Higher Education Administration with a dissertation focus on minority students in STEM (Science, 
                    Technology, Engineering, and Math) education. She holds many titles, including STEM educator, STEM consultant, STEM Coach, 
                    Curriculum developer, and Adjunct Professor.  Dr. Williams has written curriculum, developed various STEM programs, and 
                    published.  In addition, she has developed and implemented professional development for educators in various districts 
                    throughout Connecticut in STE(A)M, Science, Math, and Teaching Multicultural Students.
                </p>
                <p className="about-text">
                    Dyami Watson Jr. is a current senior studying Computer Science obtaining a Bachelor of Science and a Master of Science. Dyami has 
                    previously worked as a software developer and has experience tutoring computer science students. He has worked on a variety of 
                    projects including mobile and web applications and has experience with JavaScript, Typescript, C, C++, C#, and more. His goal is 
                    to change the world with his technological prowess.
                </p>
                <p className="about-text">
                    Board Member: Jeremiah Altius
                </p>
            </div>
            <Footer/>
            </>
        );
    }
}
export default AboutUs;